.container {
  box-sizing: border-box;
  display: grid;
  justify-content: center;
  background: white;
  border-radius: 20px;
  flex-direction: column;
  margin: 20px;
  border: 4px solid #0e7178;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  gap: 0px 0px;
  grid-auto-flow: row;
  grid-template-areas:
    ". . . . . . . . . Page"
    ". . . . . . . . . Page"
    "A . J . S . A2 . J2 ."
    ". B . K . T . B2 . K2"
    "C . L . U . C2 . L2 ."
    ". . . . . . . . . ."
    "D . M . V . D2 . M2 ."
    ". E . N . W . E2 . N2"
    "F . O . X . F2 . O2 ."
    ". . . . . . . . . ."
    "G . P . Y . G2 . P2 ."
    ". H . Q . Z . H2 . Q2"
    "I . R . Zz . I2 . R2 ."
    ". . . . . . . . . Submit"
    ". . . . . . . . . Submit";
}

.submit {
  grid-area: Submit;
}

.Page {
  grid-area: Page;
  background: #01c8d6;
  border-radius: 29px;
  width: 101px;
  height: 58px;
  color: white;
  justify-content: center;
  display: flex;
  align-items: center;
  margin-top: 1rem;
}

.Pagename {
  background: radial-gradient(circle at top left, #0f165c, #097a82);
  border-radius: 29px;
  width: 101px;
  height: 58px;
  color: white;
  justify-content: center;
  display: flex;
  align-items: center;
  margin-top: 1rem;
  margin-left: auto;
}

.A {
  grid-area: A;
}

.B {
  grid-area: B;
}

.C {
  grid-area: C;
}

.D {
  grid-area: D;
}

.E {
  grid-area: E;
}

.F {
  grid-area: F;
}

.G {
  grid-area: G;
}

.H {
  grid-area: H;
}

.I {
  grid-area: I;
}

.J {
  grid-area: J;
}

.K {
  grid-area: K;
}

.L {
  grid-area: L;
}

.M {
  grid-area: M;
}

.N {
  grid-area: N;
}

.O {
  grid-area: O;
}

.P {
  grid-area: P;
}

.Q {
  grid-area: Q;
}

.R {
  grid-area: R;
}

.S {
  grid-area: S;
}

.T {
  grid-area: T;
}

.U {
  grid-area: U;
}

.V {
  grid-area: V;
}

.W {
  grid-area: W;
}

.X {
  grid-area: X;
}

.Y {
  grid-area: Y;
}

.Z {
  grid-area: Z;
}

.Zz {
  grid-area: Zz;
}

.A2 {
  grid-area: A2;
}

.B2 {
  grid-area: B2;
}

.C2 {
  grid-area: C2;
}

.D2 {
  grid-area: D2;
}

.E2 {
  grid-area: E2;
}

.F2 {
  grid-area: F2;
}

.G2 {
  grid-area: G2;
}

.H2 {
  grid-area: H2;
}

.I2 {
  grid-area: I2;
}

.J2 {
  grid-area: J2;
}

.K2 {
  grid-area: K2;
}

.L2 {
  grid-area: L2;
}

.M2 {
  grid-area: M2;
}

.N2 {
  grid-area: N2;
}

.O2 {
  grid-area: O2;
}

.P2 {
  grid-area: P2;
}

.Q2 {
  grid-area: Q2;
}

.R2 {
  grid-area: R2;
}

.button {
  font-weight: bold;
  /* font-family: "Poppins"; */
  align-items: center;
  justify-content: center;
  display: flex;
  background: linear-gradient(90deg, #01063d -4.7%, #003b3f 93.47%);
  /* border: 2px solid #d9d9d9; */
  border-radius: 15px;
  width: 110px;
  height: 35px;
  color: white;
  text-transform: capitalize;
  letter-spacing: 1px;
  cursor: pointer;
  margin-bottom: 15px;
  margin-left: auto;
  border: none;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.button:focus {
  outline: none;
}

.button:hover {
  filter: brightness(70%);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0;
  /* <-- Apparently some margin are still there even though it's hidden */
}

.container1 {
  /* font-family: "Poppins"; */
  font-weight: bold;
  padding: 2rem;
  border: #01063d solid 6px;
  background: white;
  box-sizing: border-box;
  justify-content: center;
  display: grid;
  border-radius: 20px;
}

.divtitle {
  justify-content: center;
  display: flex;
  margin-bottom: 2rem;
}

.title {
  color: white;
  justify-content: center;
  display: flex;
  border: white solid 2px;
  box-sizing: border-box;
  background: #01063d;
  width: 700px;
}

.wrapper {
  width: 100%;
  align-items: center;
  display: grid;
  justify-content: center;
}

@media screen and (max-width: 868px) {
  .fixed-title {
    margin-left: -16%;
    display: block;
  }

  .fixed-button {
    margin-left: -40% !important;
    position: fixed;
  }
  /* .main {
    margin-right: auto;
  } */
}

@media screen and (max-width: 1200px) {
  .main {
    margin-left: auto;
  }

  .fixed-title {
    margin-right: 20rem;
  }
  .fixed-button {
    margin-top: 45rem;
    margin-left: -15%;
  }
}

.fixed-title {
  margin-right: 20rem;
}
.fixed-button {
  margin-top: 44rem;
  margin-left: 1rem;
}
