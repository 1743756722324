.container {
  padding: 50px 120px;
  background: linear-gradient(90deg, #314a80 -15.53%, #182848 95.3%);
  background-size: cover;
  min-height: 100vh;
  background-position: center bottom;
}

.container > a > img {
  position: absolute;
  left: 30px;
  top: 40px;
}

.container > div {
  margin: auto;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 380px;
  height: 60px;
  font-size: 35px;
  color: #fff;
  font-weight: 700;
  background: linear-gradient(90deg, #314a80 -15.53%, #182848 95.3%);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 49px;
}

.container > form {
  margin: auto;
  width: 380px;
}

.select {
  background: #ffffff !important;
  border: 2px solid #182848 !important;
  border-radius: 11px;
}

.option {
  font-weight: 600;
  font-size: 16px;
  line-height: 30px;

  color: #c5c5c5;
}

.form * input {
  padding: 20px 15px;
  height: 50px;

  color: rgba(50, 50, 50);

  background: #ffffff !important;
  border: 2px solid #182848 !important;
  border-radius: 11px;
}

/* selector pilihan keahlian */
.signup-keahlian {
  border: 2px solid #182848;
  border-radius: 11px;
  height: 50px;
  display: flex;
  align-items: center;
  background: #fff;
}

.form * input:hover {
  border: 2px solid #314a80;
}

.form * input::placeholder {
  /* color: #464646 !important; */
  font-weight: 600;
}

.form * select {
  padding: 20px 15px;
  height: 50px;

  background: #ffffff !important;
  border: 2px solid #314a80 !important;
  border-radius: 11px;
}

.form * select:hover {
  border: 2px solid #314a80;
}

.form * select::placeholder {
  color: #464646 !important;
}

.form * button {
  background: linear-gradient(90deg, #314a80 -15.53%, #182848 91.3%);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 11px;

  width: 120px;
  height: 40px;

  color: #fff;
  font-weight: 600;
  border: 0;
}

.form * span {
  padding: 3px 8px;
  padding-right: 0;
  border-radius: 5px;
  background-color: #182848;
  color: #ffffff;
  background-color: #fff;
  color: #8d8d8d;

  font-weight: 600 !important;
}

.form * span > a {
  color: #182848 !important;
  text-decoration: none;
  padding: 3px 8px;
  border-radius: 5px;
}

@media (max-width: 576px) {
  .container {
    padding: 12px !important;
  }
  .container > form,
  .container > div {
    width: 100%;
  }

  .form * button {
    width: 100%;
  }
}

@media (max-width: 720px) {
  .container {
    padding: 32px 0;
  }

  .container > a > img {
    position: static;
    left: 0;
    top: 0;
  }
}
