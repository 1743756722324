.custom-topbar {
  width: 100%;
  height: 120px;
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 0 32px;
  background: linear-gradient(
    180deg,
    #182848 -9.59%,
    #182848 -9.58%,
    #182848 116.13%);
  position: relative;
  z-index: 1;
}

@media (max-width: 576px) {
  .custom-topbar {
    height: 80px;
  }
}
