.containerr {
  /* background-color: white; */
}
.tiucontainer {
  /* margin-top: -20px; */
  background-color: white;
  /* border: #0e7178 solid; */
  border-radius: 20px;
}

.tiucontainer .ant-row {
  margin-left: 0;
  margin-right: 0;
}

.tiucontainer .ant-radio {
  border: solid #6994b6 3px !important;
  border-radius: 50%;
}

.tiucontainerhasil {
  background-color: white;
  height: 100vh;
  border: #0e7178 solid;
  border-radius: 20px;
}
.tiucontainerr {
  background-color: white;
  height: 100vh;
  border: #0e7178 solid;
  border-radius: 20px;
  margin-top: 40px;
}
.divjudul {
  display: flex;
  justify-content: center;
  margin-bottom: -50px;
}
.tiujudul {
  justify-content: center;
  align-items: center;
  display: flex;
  color: white;
  background: linear-gradient(180deg, #00656c 0%, #00d0de 100%);
  border-radius: 0px 0px 20px 20px;
  width: 800px;
  height: 50px;
  font-size: 25px;
  font-weight: 600;
}
.divcol1 {
  justify-content: center;
  align-items: center;
  display: flex;
  height: 50%;
}
.divsoal1 {
  /* width: 100%; */
  height: 75%;
  margin-top: 90px;
  justify-content: center;
}
.titlecontoh {
  justify-content: center;
  font-weight: bold;
  align-items: center;
  display: flex;
}
.divtitlecontoh1 {
  justify-content: center;
  display: flex;
  align-items: center;
  font-weight: bold;
}
.titlecontoh1 {
  width: 610px;
}
.contoh1 {
  justify-content: center;
  display: flex;
  align-items: center;
}
.foto1 {
  width: 820px;
  margin-top: 20px;
}
.foto2 {
  width: 800px;
  margin-top: 20px;
}
.tiuradio {
  justify-content: center;
  display: flex;
  height: 20px;
  /* background-color: "red"; */
}
.tiuform {
  margin: 10px 0px 0px 430px;
}
.ltradio * input {
  margin: -10px 26px;
  border: #00d0de solid;
}
.divcontohsoal1 {
  justify-content: center;
  display: flex;
  align-items: center;
  margin-top: 20px;
  line-height: 20px;
}
.contohsoal1 {
  width: 100%;
  font-weight: bold;
}
.divsoal2 {
  width: 90%;
  height: 75%;
  margin-top: -70px;
  justify-content: center;
}
.divbutton {
  justify-content: end;
  /* margin: -90px 50px 0px 0px; */
  display: flex;
}
.next {
  color: white;
  width: 12%;
  background: linear-gradient(90deg, #00ff94 -9.35%, #00c9d6 100%);
  border-radius: 20px;
  align-items: center;
  justify-content: center;
  display: flex;
  font-size: 20px;
  font-weight: 600;
}

.divcol2 {
  justify-content: center;
  align-items: center;
  display: flex;
}
.divsoall1 {
  /* width: 80%; */
}
.divsoall2 {
  width: 100%;
  margin-top: 90px;
  font-weight: bold;
  justify-content: center;
  align-items: center;
  display: flex;
}
.judull2 {
  justify-content: center;
  align-items: center;
  display: flex;
  width: 80%;

  margin-bottom: 20px;
}
.fotosoal {
  height: 60px;
  width: 500px;
  justify-content: center;
  display: flex;
  align-items: center;
}
.tiuradio2 {
  justify-content: center;
  display: flex;
  margin-top: 5px;
  width: 95%;
  height: 20px;
}
.tiuform2 {
  margin: 0px 10px 0px 325px;
}
.ltradio2 * input {
  margin: -5px 8px;
  border: #00d0de solid;
}
.divnext {
  justify-content: end;

  width: 94%;
  display: flex;
}
.panahnext {
  align-items: center;
  display: flex;
}

.nextt {
  color: white;
  height: 50%;
  background: linear-gradient(180deg, #01063d 0%, #005466 100%);
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  display: flex;
  margin-left: 5px;
}
.nextt2 {
  color: white;
  height: 50%;
  background: linear-gradient(180deg, #01063d 0%, #005466 100%);
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  display: flex;
}
.divnextpage {
  width: 123px;
  height: 40px;
  background: linear-gradient(90deg, #01063d -9.35%, #137978 100%);
  border-radius: 28px;
  font-size: 24px;
  line-height: 36px;
  text-align: center;
  color: #ffffff;
  margin-top: 20px;
  margin-left: auto;
}
.nextpage {
  text-decoration: none;
  color: white;
  font-weight: 600;
  align-items: center;
}

.koreksib {
  position: absolute;
  color: white;
  justify-content: center;
  align-items: center;
  display: flex;
  font-size: 20px;
  font-weight: 800;
  margin-top: 10px;
  margin-left: 20px;
  width: 12%;
  height: 5%;
  background-color: #138233;  
  border-radius: 30px;
}
.koreksis {
  position: absolute;
  color: white;
  justify-content: center;
  align-items: center;
  display: flex;
  font-size: 20px;
  font-weight: 800;
  margin-top: 10px;
  margin-left: 175px;
  width: 12%;
  height: 5%;
  background-color: #cc0d0d;
  border-radius: 30px;
}

.score {
  background: linear-gradient(90.02deg, #01063d -6.97%, #009ba5 104.44%);
  border-radius: 2px;
  width: 80%;
  height: 30px;
  color: black;
  margin-left: 10px;
  margin-top: 40px;
}

.divnextchart {
  width: 123px;
  height: 35px;
  background: linear-gradient(90deg, #01063d -9.35%, #235868 100%);
  border-radius: 28px;
  font-size: 24px;
  line-height: 36px;
  text-align: center;
  color: #ffffff;
  margin-top: -30px;
  margin-left: auto;
}

.nextchart {
  text-decoration: none;
  color: white;
  font-weight: 700;
}
.divscore {
  width: 90%;
}

.radio-custom .ant-radio-wrapper {
  border: 2px solid transparent;
  border-radius: 8px;
  padding: 4px;
}

.radio-custom .ant-radio-wrapper-checked {
  border: 2px solid greenyellow;
  border-radius: 8px;
  padding: 4px;
}

.radio-custom .ant-radio {
  position: absolute;
  left: -9999px;
  overflow: hidden;
}

.radio-custom .ant-radio + * {
  display: block;
  padding: 0;
  overflow: hidden;
  border-radius: 8px;
  overflow: hidden;
}

.tiutest-guide-wrap {
  font-weight: 700;
  font-size: 18px;
  line-height: 30px;
  text-align: center;

  color: #383838;
}

.tiutest-guide-wrap > div {
  margin-bottom: 50px;
}

.tiutest-guide-next-btn {
  width: 123px;
  height: 35px;

  display: flex;
  justify-content: center;
  align-items: center;

  margin-left: auto;

  background: linear-gradient(90deg, #01063d -9.35%, #165055 100%);
  border-radius: 28px;
  border: none;

  font-weight: 700;
  font-size: 18px;
}

.button {
  font-weight: bold;
  /* font-family: "Poppins"; */
  align-items: center;
  justify-content: center;
  display: flex;
  background: linear-gradient(90deg, #01063d -4.7%, #003b3f 93.47%);
  /* border: 2px solid #d9d9d9; */
  border-radius: 15px;
  width: 110px;
  height: 35px;
  color: white;
  text-transform: capitalize;
  letter-spacing: 1px;
  cursor: pointer;
  margin-bottom: 15px;
  margin-left: auto;
  border: none;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

@media screen and (max-width: 1200px) {
  .tiucontainerhasil {
    height: max-content;
  }
  .tiucontainerr {
    height: max-content;
  }
  .tiucontainer {
    width: auto;
  }
  .tiujudul {
    width: 80%;
  }
  .tiuform {
    margin: 0px -20px 0px 390px;
  }
  .ltradio * input {
    margin: 0px 25px;
  }
  .next {
    width: auto;
  }
  .tiuradio2 {
    width: auto;
  }
  .tiuform2 {
    margin: 0px -75px 0px 200px;
  }
  .ltradio2 * input {
    margin: 0px 8px;
  }
  .nextt2 {
    margin-top: 20px;
  }
  .nextt {
    margin-left: 5px;
    margin-top: 20px;
  }
  .koreksib {
    width: 15%;
  }
  .koreksis {
    width: 15%;
    margin-left: 20px;
    margin-top: 50px;
  }
  .score {
    width: 80%;
  }
  .divnextchart {
    margin-top: -33px;
  }
}
@media screen and (max-width: 992px) {
  .tiucontainerhasil {
    height: max-content;
  }
  .tiucontainerr {
    height: max-content;
  }
  .tiucontainer {
    height: 140vh;
    width: auto;
  }
  .tiujudul {
    width: 80%;
  }
  .tiuform {
    margin: 0px -20px 0px 360px;
  }
  .ltradio * input {
    margin: 0px 25px;
  }
  .next {
    width: auto;
  }
  .tiuradio2 {
    width: auto;
  }
  .tiuform2 {
    margin: 0px -75px 0px 200px;
  }
  .ltradio2 * input {
    margin: 0px 8px;
  }
  .nextt2 {
    margin-top: 20px;
  }
  .nextt {
    margin-left: 5px;
    margin-top: 20px;
  }
  .koreksib {
    width: 15%;
  }
  .koreksis {
    width: 15%;
    margin-left: 20px;
    margin-top: 50px;
  }
  .score {
    width: 80%;
  }
  .divnextchart {
    margin-top: -33px;
  }
}
@media screen and (max-width: 768px) {
  .tiucontainerhasil {
    height: max-content;
  }
  .tiucontainerr {
    height: max-content;
  }
  .tiucontainer {
    height: 140vh;
    width: auto;
  }
  .tiujudul {
    width: 80%;
  }
  .tiuform {
    margin: 0px -20px 0px 310px;
  }
  .ltradio * input {
    margin: 0px 16px;
  }
  .next {
    width: auto;
  }
  .tiuradio2 {
    width: auto;
  }
  .tiuform2 {
    margin: 0px -75px 0px 200px;
  }
  .ltradio2 * input {
    margin: 0px 8px;
  }
  .nextt2 {
    margin-top: 20px;
  }
  .nextt {
    margin-left: 5px;
    margin-top: 20px;
  }
  .koreksib {
    width: 15%;
  }
  .koreksis {
    width: 15%;
    margin-left: 20px;
    margin-top: 50px;
  }
  .score {
    width: 75%;
  }
  .divnextchart {
    margin-top: -33px;
  }
}
@media screen and (max-width: 576px) {
  .containerr {
    /* padding: 16px; */
  }

  .judull2 {
    width: 100%;
    text-align: center;
  }

  .divnextpage {
    width: 100%;
  }

  .tiucontainerhasil {
    height: max-content;
  }
  .tiucontainerr {
    height: max-content;
  }
  .tiucontainer {
    width: auto;
  }
  .tiujudul {
    width: 80%;
  }
  .tiuform {
    margin: 0px -230px 0px 10px;
  }
  .ltradio * input {
    margin: 0px 8px;
  }
  .next {
    width: auto;
  }
  .tiuradio2 {
    width: auto;
  }
  .tiuform2 {
    margin: 0px -75px 0px 150px;
  }
  .ltradio2 * input {
    margin: 0px 4px;
  }
  .nextt2 {
    margin-top: 30px;
  }
  .nextt {
    margin-left: 5px;
    margin-top: 30px;
  }
  .koreksib {
    width: 15%;
  }
  .koreksis {
    width: 15%;
    margin-left: 20px;
    margin-top: 50px;
  }
  .score {
    width: 70%;
  }
  .divnextchart {
    margin-top: -33px;
  }
}
