.containerr {
  /* background-color: white; */
}

.skolastic-header {
  /* display: flex;
  justify-content: center; */
  /* position: absolute; */
  display: flex;
}
.cardhasil1 {
  /* position: absolute; */
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin-right: 900px; */
  width: 90px;
  height: 35px;
  /* margin-top: 40px; */
  color: aliceblue;
  font-size: 15px;
  font-weight: 500;

  background: #138233;
  border-radius: 28px;

  margin-right: 5px;
}
.button {
  font-weight: bold;
  /* font-family: "Poppins"; */
  align-items: center;
  justify-content: center;
  display: flex;
  background: linear-gradient(90deg, #01063d -4.7%, #003b3f 93.47%);
  /* border: 2px solid #d9d9d9; */
  border-radius: 15px;
  width: 110px;
  height: 35px;
  color: white;
  text-transform: capitalize;
  letter-spacing: 1px;
  cursor: pointer;
  margin-bottom: 15px;
  margin-left: auto;
  border: none;
  margin-top: 15px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.cardhasil2 {
  /* position: absolute; */
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-right: 700px; */
  width: 90px;
  height: 35px;
  /* margin-top: 40px; */
  color: aliceblue;
  font-size: 15px;
  font-weight: 500;

  background: #cc0d0d;
  border-radius: 28px;
}
/* .logikacontainer {
  margin-top: -20px;
  background-color: white;
  border-radius: 20px;
} */
.logheader {
  display: flex;
  margin-top: 0%;
  justify-content: center;
}
.benar {
  /* position: absolute; */
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 900px;
  width: 90px;
  height: 35px;
  margin-top: 40px;
  color: aliceblue;
  font-size: 15px;
  font-weight: 500;

  background: #138233;
  border-radius: 28px;

  /* margin-right: 5px; */
}

.salah {
  /* position: absolute; */
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-right: 700px; */
  width: 90px;
  height: 35px;
  /* margin-top: 40px; */
  color: aliceblue;
  font-size: 15px;
  font-weight: 500;

  background: #cc0d0d;
  border-radius: 28px;
}
.radio {
  font-size: 23px;
  color: #ffffff;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.ltcontainerhasil {
  background-color: #ffffff;
  border: #01063d solid;
  padding: 32px;
  padding-top: 0;
  border-radius: 22px;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
}
.ltcontainer {
  background-color: #ffffff;
  border: #01063d solid;
  padding: 30px;
  padding-top: 0;
  border-radius: 20px;
  justify-content: center;
  align-items: center;
}

.ltjudul0 {
  display: flex;
  justify-content: center;
  /* margin-bottom: -50px; */
}

.ltjudul {
  justify-content: center;
  align-items: center;
  display: flex;
  color: white;
  background: linear-gradient(180deg, #01063d 0%, #01063d 100%);
  border-radius: 0px 0px 20px 20px;
  width: 800px;
  height: 50px;
  font-size: 25px;
  font-weight: 600;
}

.ltheader {
  display: flex;
  justify-content: center;
}

.ltcontent {
  display: flex;
  justify-content: center;
}

.ltcardnumber {
  margin-top: 20px;
  background-color: #01063d;
  color: white;
  font-size: 25px;
  width: 10%;
  border-radius: 100px;
  display: flex;
  align-items: center;
}

.ltcard {
  background: linear-gradient(5.88deg, #01063d -60.64%, #097a82 148.24%);
  color: white;
  margin-top: 20px;
  width: 100%;
  font-size: 20px;
  border-radius: 20px;
  /* height: 500px; */
  flex-direction: column;
}

.ltsoal {
  width: 700px;
  height: 150px;
  display: flex;

  text-align: center;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
  font-size: 25px;
}

.ltradio {
  display: flex;
  /* flex-direction: column; */
}

.ltpg {
  /* justify-content: start; */
  display: flex;
  font-size: 25px;
  width: 900px;
  height: 250px;
}

.divsoal {
  justify-content: center;
  display: flex;

  height: 150px;
  margin: 1rem;
}
.divpg {
  justify-content: space-between;
  align-items: flex-end;
  margin: 1rem;

  margin-top: -15px;
  display: flex;
}

.divnext {
  justify-content: end;

  width: 99%;
  display: flex;
}

.next {
  color: white;

  background: linear-gradient(180deg, #01063d 0%, #232968 100%);
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  display: flex;
  font-size: 20px;
  font-weight: 200px;
}

.panahnext {
  align-items: center;
  display: flex;
}

/* update nugrah */
.logikatest-button {
  margin: 0 8px !important;
  cursor: pointer;
}

.nextpage {
  text-decoration: none;
  color: white;
  font-weight: 700;
}

/* logikahasil css */
.divnextpage {
  width: 123px;
  height: 35px;
  background: linear-gradient(90deg, #01063d -9.35%, #232968 100%);
  border-radius: 28px;
  font-size: 24px;
  line-height: 36px;
  text-align: center;
  color: #ffffff;
  margin-top: 35px;
  margin-left: auto;
}

.koreksib {
  position: absolute;
  color: white;
  justify-content: center;
  align-items: center;
  display: flex;
  font-size: 20px;
  font-weight: 800;
  margin-top: -6.2rem;
  margin-left: 20px;
  width: 12%;
  height: 5%;
  background-color: #138233;
  border-radius: 30px;
}
.koreksis {
  position: absolute;
  color: white;
  justify-content: center;
  align-items: center;
  display: flex;
  font-size: 20px;
  font-weight: 800;
  margin-top: -6.2rem;
  margin-left: 175px;
  width: 12%;
  height: 5%;
  background-color: #cc0d0d;
  border-radius: 30px;
}
.ltradiobenar * input {
  background-color: red;
}

.score {
  background: linear-gradient(90.02deg, #01063d -6.97%, #097a82 104.44%);
  border-radius: 2px;
  width: 80%;
  height: 30px;
  color: black;
  margin-left: 10px;
  margin-top: 90px;
}

.divnextchart {
  width: 123px;
  height: 35px;
  background: linear-gradient(90deg, #01063d -9.35%, #232968 100%);
  border-radius: 28px;
  font-size: 24px;
  line-height: 36px;
  text-align: center;
  color: #ffffff;
  margin-top: 20px;
  margin-left: auto;
  margin-bottom: 15px;
}

.nextchart {
  text-decoration: none;
  color: white;
  font-weight: 700;
}
@media screen and (max-width: 1200px) {
  .ltcontainer {
    height: max-content;
  }
  .divnext {
    width: 95%;
  }
  .koreksis {
    margin-left: 150px;
  }
}

@media screen and (max-width: 992px) {
  .ltcontainer {
    height: max-content;
  }
  .ltjudul {
    width: 80%;
  }
  .divnext {
    width: 610px;
  }
  .koreksis {
    margin-left: 120px;
  }
  .divnextchart {
    width: 17%;
  }
}

@media screen and (max-width: 768px) {
  .ltcontainer {
    height: max-content;
  }
  .ltjudul {
    width: 600px;
  }
  .divsoal {
    width: 600px;
  }
  .divnext {
    justify-content: space-between;
    width: 627px;
  }
  .koreksis {
    margin-left: 120px;
  }
  .divnextchart {
    width: 16%;
  }
}

@media screen and (max-width: 576px) {
  .ltcontainer {
    height: max-content;
  }
  .ltjudul {
    width: 80%;
  }
  .ltcardnumber {
    width: 100px;
  }
  .ltcard {
    /* height: 700px; */
  }
  .divnext {
    justify-content: flex-end;

    margin-top: 200px;
    padding: 20px;
    width: 100%;
  }
  .divsoal {
    width: 90%;
  }
  .divnextpage {
    margin-top: 45px;
  }
  .koreksib {
    margin-left: 5px;
    width: 15%;
  }
  .koreksis {
    width: 15%;
    margin-left: 100px;
  }
  .koreksib {
    width: 20%;
  }
  .koreksis {
    margin-left: 5px;
    margin-top: 50px;
    width: 20%;
  }
  .score {
    margin-top: 290px;
    width: 70%;
  }
}
