.container {
  padding: 50px 120px;
  background: url("/src/assets/signin/wpbg.png") no-repeat;
  background-size: cover;
  min-height: 100vh;
  background-position: center bottom;
  background-position-y: 60%;
  /* opacity: 1; */

  overflow-x: hiddeN;

   background-color: linear-gradient(
    #314a80 -9.59%,
    #314a80 -9.58%,
    #182848 116.13%,
    rgba(238, 238, 238, 0) 116.14%
    /* 89.71deg,
    #182848 -9.59%,
    #314a80 -9.58%,
    #314a80 116.13%,
    rgba(231, 231, 231, 0) 116.14% */
  )
}

.row {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 35px;
}

.title {
  background: linear-gradient(90deg, #314a80 -15.53%, #182848 95.3%);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 49px;
  width: 350px;
  height: 62px;

  font-weight: 700;
  font-size: 23px;
  line-height: calc(54px - 10%);

  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}

.left {
  text-align: center;
  /* text-align: left; */
}

.left .responsive {
  text-align: center;
}

.right {
  padding: 0 35px;
  width: 580px;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}

.form * input {
  padding: 20px 15px;
  height: 50px;

  background: #ffffff !important;
  border: 2px solid #314a80 !important;
  border-radius: 11px;
}

.form * input:hover {
  border: 2px solid #314a80;
}

.form * button {
  background: linear-gradient(90deg, #314a80 -15.53%, #182848 91.3%);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 11px;

  
  width: 120px;
  height: 40px;

  color: #fff;
  font-weight: 600;
  border: 0;

  margin-right: 10px;
}

.submit-btn {
  display: flex;
  align-items: center;
}

.submit-btn > div {
  color: #8d8d8d;
  background: #ffffff;

  padding: 3px 10px !important;
  border-radius: 5px;
  font-weight: 600;
}

.form * span > a {
  color: #182848 !important;
  text-decoration: none;
}

.images {
  align-items: center;
}

@media screen and (min-width: 300px) and (max-width: 1200px) {
  .container {
    padding: 50px 12px;
  }

  .title,
  .form * span,
  .right {
    width: 100%;
  }

  .right,
  .form * span {
    padding: 0;
  }

  .form * input {
    margin: 0;
    width: 100%;
  }

  .submit-btn {
    display: block;
  }

  .submit-btn > div {
    margin-top: 10px;
  }

  /* .images{
    max-width: 50%;
    display: block;
    height: auto;
  } */
}
