.table-style thead.ant-table-thead tr .ant-table-cell {
  background-color: #038FDE !important;
  color: #ffffff;
}
.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item-description {
  min-width: 200px;
}
.table-non-hover .ant-table-tbody > tr.ant-table-row:hover > td {
  background: none !important;
}
div.ant-upload.ant-upload-select.ant-upload-select-text {
  width: 100%;
}
.site-page-header {
  border: 1px solid #ebedf0;
}
.ant-card-head {
  background-color: #ffff;
}
.knowledge-submission .ant-card-head .ant-tabs-top {
  color: #ffff;
}
.button-style,
.button-style:hover,
.button-style:active {
  border: #ddac2a;
  background-color: #ddac2a !important;
  color: #ffffff !important;
}
