.sidebar-user-profile {
  /*background-color: #166780 !important;
  */background: radial-gradient(circle at top left, #3e5177, #4a5f89);
  padding: 10px;
  border-radius: 25px;
  margin: 0 10px;
  color: #ededed;
  font-weight: 700;
  color: #ededed;
  display: flex;
  align-items: center;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  -ms-border-radius: 25px;
  -o-border-radius: 25px;
}

.sidebar-content {
  border: 0 !important;
  background-color: transparent !important;
}
.sidebar-content * a {
  text-decoration: none;
}

.sidebar-list {
  display: flex;
  text-align: center;
  align-items: center;
  margin:  10 15px;
  padding-left: -40px !important;
  border-radius: 20px;
  height: 55px !important;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
}

.ant-menu-item-selected {
  background: linear-gradient(
    89.71deg,
    #262D3F -9.59%,
    #3A4D70 -9.58%,
    #3A4D70 116.13%,
    rgba(231, 231, 231, 0) 116.14%
    /* 89.71deg,
    #182848 -9.59%,
    #314a80 -9.58%,
    #314a80 116.13%,
    rgba(231, 231, 231, 0) 116.14% */
  ) !important;
}

.sidebar-name-wrap {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 138px;
}

.sidebar-name-wrap > div:nth-child(1) {
}

.sidebar-name {
  font-weight: 600;
  font-size: 17px;
  margin: 20px 0;
  text-align: start !important;
  width: 100%;

  color: #fff;
}

.sidebar-title {
  font-weight: 700;
  font-size: 17.783px;
  line-height: 27px;

  color: #fff;
}

.ant-layout-sider-collapsed .gx-layout-sider-scrollbar {
  height: 418px !important;
}

.sidebar-logout-btn-wrap {
}
.sidebar-logout-btn {
  width: 70%;
  height: 40px;
  cursor: pointer;
  background: radial-gradient(circle at top left, #3e5177, #4a5f89);
  border-radius: 23.1179px;
  margin: auto;
  font-weight: 700;
  font-size: 17.783px;
  line-height: 27px;
  text-align: center;
  padding: 3px;
  color: #ededed;
  -webkit-border-radius: 23.1179px;
  -moz-border-radius: 23.1179px;
  -ms-border-radius: 23.1179px;
  -o-border-radius: 23.1179px;
}

.sidebar-user-children::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}
