.minatkarir-wrap {
  box-sizing: border-box;
  border: 4px solid #0e7178;

  border-radius: 22px;
  /* margin: 20px 0; */
  background-color: #fff;
}

.time01 {
  /* margin-top: 30px; */
  text-align: center;
  color: #3a3a3a;

  font-weight: 700;
  font-size: 36px;
}
.Pagename {
  background: #01c8d6;
  border-radius: 29px;
  width: 101px;
  height: 58px;
  color: white;
  justify-content: center;
  display: flex;
  align-items: center;
  margin-top: 1rem;
  margin-left: auto;
}
.score {
  background: linear-gradient(90.02deg, #00ff94 -6.97%, #009ba5 104.44%);
  border-radius: 2px;
  width: 99%;
  height: 30px;
  color: black;
  border-radius: 8px;
}
.div011 {
  display: flex;
  justify-content: center;
  align-items: center;
}
.divnext {
  margin-bottom: 60px;
  margin-right: 35px;
  display: flex;
}
.divnextpage {
  margin-bottom: 60px;
  width: 150px;
  height: 100px;
  margin-right: 30px;
}
.buttonback {
  width: 150px;
  height: 100px;
}

.card011 {
  border-radius: 0px 0px 10px 10px;
  background: linear-gradient(180deg, #00656c 0%, #00d0de 100%);
  box-sizing: border-box;
  align-items: center;
  display: flex;
  justify-content: center;
  color: white !important;
  height: 50px;
  width: 500px;
  position: relative;
  text-transform: uppercase;
  font-size: 25px;
  font-weight: 600;
}

.img011 {
  width: 240px;
  height: 240px;
  /* margin-top: 30px; */
}
.img0112 {
  width: 280px;
  height: 280px;
  margin-top: 30px;
}
.img012 {
  width: 290px;
  height: 464px;
  display: flex;
  justify-content: center;
}
.imgbox01 {
  /* width: 340px;
  height: 300px; */
}

.paragraf01 {
  /* width: 475px; */
  /* margin-left: 60px; */
  font-weight: 600;
  color: #343434;
  margin-top: 10px;
}

.container9 {
  margin-top: 50px;
  box-sizing: border-box;
  border: 4px solid #0e7178;
  height: 100vh;
  border-radius: 10px;
}

.box0111 {
  box-sizing: border-box;
  justify-content: center;
  display: flex;
  justify-content: center;
  /* border: 4px solid #0e7178;
  width: 380px;
  height: 360px; */
  /* border-radius: 10px; */
  /* background: rgba(217, 217, 217, 0.74); */

  width: 383.8px;
  height: 383.8px;

  background: rgba(217, 217, 217, 0.74);
  border: 6.73333px solid #01c5d3;
  border-radius: 21.8833px;
}

.row0111 {
  margin-top: 40px;
  display: flex;
  justify-content: center;
}
.buttons1 {
  width: 111px;
  height: 50px;
  margin-bottom: 60px;
  color: aliceblue;
  /* border: red solid; */

  background: linear-gradient(90deg, #02deed -4.7%, #006067 93.47%);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 31px;
}
.buttons2 {
  margin-left: 100px;
  width: 111px;
  height: 50px;
  margin-bottom: 60px;
  color: aliceblue;
  /* border: red solid; */

  background: linear-gradient(90deg, #02deed -4.7%, #006067 93.47%);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 31px;
}
.upload01 {
  display: flex;
  justify-content: center;
  border: none;
  align-items: center;
  font-weight: 600;

  /* position: absolute; */
  width: 111px;
  height: 35px;
  /* margin-top: 150px; */
  /* color: rgb(50, 50, 50) !important; */
  color: #fff;

  background: linear-gradient(90deg, #02deed -4.7%, #006067 93.47%);
  border-radius: 31px;
}
.col1000 {
  /* width: 500px; */
  height: 100px;
  margin-top: 80px;
  display: flex;
  justify-content: end;
  /* align-items: flex-end; */
}

.upload__image-wrapper {
  display: flex;
  align-items: center;
  padding: 8px;
}

.minatkarir__buttonback {
  background: linear-gradient(90deg, #02deed -4.7%, #006067 93.47%);
  box-shadow: 0px 3.36667px 3.36667px rgba(0, 0, 0, 0.25);
  border-radius: 26.0917px;

  width: 152.34px;
  height: 42.08px;

  display: flex;
  align-items: center;
  justify-content: center;

  font-weight: 700;
  font-size: 16px;

  color: #ffffff;

  margin-left: auto;
}

/* 4k-2560px */
@media only screen and (max-width: 2560px) {
  .container011 {
    /* box-sizing: border-box;
    border: 4px solid #0e7178;
    height: 100vh;
    border-radius: 10px; */
  }
  .box0111 {
    /* box-sizing: border-box;
    justify-content: center;
    display: flex;
    justify-content: center;
    border: 4px solid #0e7178;
    width: 380px;
    height: 360px;
    border-radius: 10px;
    background: rgba(217, 217, 217, 0.74); */
  }
  .img011 {
    /* width: 240px;
    height: 240px; */
    /* margin-top: 30px; */
  }
  .upload01 {
    display: flex;
    justify-content: center;
    /* position: absolute; */
    width: 111px;
    height: 35px;
    /* margin-top: 150px; */
    color: aliceblue;

    background: linear-gradient(90deg, #02deed -4.7%, #006067 93.47%);
    border-radius: 31px;
  }
  /* .paragraf01 {
    width: 475px;
    margin-left: 350px;
    font-style: normal;
    font-weight: 500;
    color: #343434;
    margin-top: 10px;
  } */
  .buttons1 {
    margin-left: 10px;
    width: 181px;
    height: 50px;
    margin-bottom: 60px;
    color: aliceblue;
    /* border: red solid; */

    background: linear-gradient(90deg, #02deed -4.7%, #006067 93.47%);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 31px;
  }
  .buttons2 {
    margin-left: 0 !important;
    width: 181px;
    height: 50px;
    margin-bottom: 60px;
    color: aliceblue;
    /* border: red solid; */

    background: linear-gradient(90deg, #02deed -4.7%, #006067 93.47%);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 31px;
  }
  .card011 {
    color: #343434;
  }
}

/* Laptop L-1440px */
@media only screen and (max-width: 1440px) {
  .container011 {
    /* box-sizing: border-box;
    border: 4px solid #0e7178;
    height: 630px;
    border-radius: 10px; */
  }
  .box0111 {
    /* box-sizing: border-box;
    justify-content: center;
    display: flex;
    justify-content: center;
    border: 4px solid #0e7178;
    width: 380px;
    height: 360px;
    border-radius: 10px;
    background: rgba(217, 217, 217, 0.74); */
  }
  .img011 {
    width: 240px;
    height: 240px;
    margin-top: 30px;
  }
  .upload01 {
    display: flex;
    justify-content: center;
    /* position: absolute; */
    width: 111px;
    height: 35px;
    /* margin-top: 150px; */
    color: aliceblue;

    background: linear-gradient(90deg, #02deed -4.7%, #006067 93.47%);
    border-radius: 31px;
  }
  /* .paragraf01 {
    width: 475px;
    margin-left: 65px;
    font-style: normal;
    font-weight: 500;
    color: #343434;
    margin-top: 10px;
  } */
  .buttons1 {
    margin-left: 80px;
    width: 111px;
    height: 50px;
    margin-bottom: 60px;
    color: aliceblue;
    /* border: red solid; */

    background: linear-gradient(90deg, #02deed -4.7%, #006067 93.47%);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 31px;
  }
  .buttons2 {
    margin-left: 80px;
    width: 111px;
    height: 50px;
    margin-bottom: 60px;
    color: aliceblue;
    /* border: red solid; */

    background: linear-gradient(90deg, #02deed -4.7%, #006067 93.47%);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 31px;
  }
  .card011 {
    color: wheat;
  }
}

/* Laptop-1024px */
@media only screen and (max-width: 1024px) {
  .container011 {
    /* box-sizing: border-box;
    border: 4px solid #0e7178;
    height: 1150px;
    border-radius: 10px; */
  }
  .box0111 {
    /* box-sizing: border-box;
    justify-content: center;
    display: flex;
    justify-content: center;
    border: 4px solid #0e7178;
    width: 380px;
    height: 360px;
    border-radius: 10px;
    background: rgba(217, 217, 217, 0.74); */
  }
  .img011 {
    width: 240px;
    height: 240px;
    margin-top: 30px;
  }
  .upload01 {
    display: flex;
    justify-content: center;
    /* position: absolute; */
    width: 111px;
    height: 35px;
    /* margin-top: 150px; */
    color: aliceblue;

    background: linear-gradient(90deg, #02deed -4.7%, #006067 93.47%);
    border-radius: 31px;
  }
  /* .paragraf01 {
    width: 475px;
    margin-left: 164px;
    font-style: normal;
    font-weight: 500;
    color: #343434;
    margin-top: 10px;
  } */
  .buttons1 {
    margin-left: 80px;
    width: 111px;
    height: 50px;
    margin-bottom: 60px;
    color: aliceblue;
    /* border: red solid; */

    background: linear-gradient(90deg, #02deed -4.7%, #006067 93.47%);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 31px;
  }
  .buttons2 {
    margin-left: 80px;
    width: 111px;
    height: 50px;
    margin-bottom: 60px;
    color: aliceblue;
    /* border: red solid; */

    background: linear-gradient(90deg, #02deed -4.7%, #006067 93.47%);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 31px;
  }
  .card011 {
    color: wheat;
  }
}

@media (max-width: 991px) {
  .paragraf01 {
    text-align: center;
  }
}

/* Mobile L-425px */
@media (max-width: 576px) {
  .container011 {
    /* box-sizing: border-box;
    border: 4px solid #0e7178;
    height: 1150px;
    border-radius: 10px; */
  }
  .box0111 {
    /* box-sizing: border-box;
    justify-content: center;
    display: flex;
    justify-content: center;
    border: 4px solid #0e7178;
    width: 380px;
    height: 360px;
    border-radius: 10px;
    background: rgba(217, 217, 217, 0.74); */
  }
  .img011 {
    width: 240px;
    height: 240px;
    margin-top: 30px;
  }
  .upload01 {
    display: flex;
    justify-content: center;
    /* position: absolute; */
    width: 111px;
    height: 35px;
    /* margin-top: 150px; */
    color: aliceblue;

    background: linear-gradient(90deg, #02deed -4.7%, #006067 93.47%);
    border-radius: 31px;
  }
  /* .paragraf01 {
    width: 375px;
    margin-left: 10px;
    font-style: normal;
    font-weight: 500;
    color: #343434;
    margin-top: 10px;
  } */
  .buttons1 {
    margin-right: 10px;
    width: 111px;
    height: 50px;
    margin-bottom: 100px;
    color: aliceblue;
    /* border: red solid; */

    background: linear-gradient(90deg, #02deed -4.7%, #006067 93.47%);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 31px;
  }
  .buttons2 {
    margin-right: 40px;
    width: 70px;
    height: 50px;
    margin-bottom: 100px;
    color: aliceblue;
    /* border: red solid; */

    background: linear-gradient(90deg, #02deed -4.7%, #006067 93.47%);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 31px;
  }
  .card011 {
    color: wheat;
  }

  .minatkarir__buttonback {
    width: 100%;
  }
}

/* Mobile M-375px */
@media only screen and (max-width: 375px) {
  .container011 {
    /* box-sizing: border-box;
    border: 4px solid #0e7178;
    height: 1150px;
    border-radius: 10px; */
  }
  .box0111 {
    box-sizing: border-box;
    justify-content: center;
    display: flex;
    justify-content: center;
    border: 4px solid #0e7178;
    width: 380px;
    height: 360px;
    border-radius: 10px;
    background: rgba(217, 217, 217, 0.74);
  }
  .img011 {
    width: 240px;
    height: 240px;
    margin-top: 30px;
  }
  .upload01 {
    display: flex;
    justify-content: center;
    /* position: absolute; */
    width: 111px;
    height: 35px;
    /* margin-top: 150px; */
    color: aliceblue;

    background: linear-gradient(90deg, #02deed -4.7%, #006067 93.47%);
    border-radius: 31px;
  }
  /* .paragraf01 {
    width: 275px;
    margin-left: 10px;
    font-style: normal;
    font-weight: 500;
    color: #343434;
    margin-top: 10px;
  } */
  .buttons1 {
    margin-right: 10px;
    width: 111px;
    height: 50px;
    margin-bottom: 100px;
    color: aliceblue;
    /* border: red solid; */

    background: linear-gradient(90deg, #02deed -4.7%, #006067 93.47%);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 31px;
  }
  .buttons2 {
    margin-right: 40px;
    width: 111px;
    height: 50px;
    margin-bottom: 100px;
    color: aliceblue;
    /* border: red solid; */

    background: linear-gradient(90deg, #02deed -4.7%, #006067 93.47%);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 31px;
  }
  .card011 {
    color: wheat;
  }
}
