.testing-container {
  /*margin: -32px -32px 0;
  *//*padding: 32px;
  */background: #fff;
  border-radius:28px ;
  -webkit-border-radius:28px ;
  -moz-border-radius:28px ;
  -ms-border-radius:28px ;
  -o-border-radius:28px ;
}

.testing-container-wrap {
  min-height: 50px;

  padding: 80px;

  border: 3.36667px solid #314a80;
  border-radius: 18px;
}

.testing-container-title-wrap {
  margin-top: calc(-63px + -63px);
  /* background: radial-gradient(circle at top left, #0f165c, #097a82); */
  background: linear-gradient(180deg, #314a80 0%, #182848 100%);
  border-radius: 28px;
  width: 90%;
  height: 80px;

  margin-right: auto;
  margin-left: auto;

  position: relative;
  z-index: 1;
}

.testing-container-title-wrap > div {
  padding-top: 18px;
  font-weight: 700;
  font-size: 25.3px;
  line-height: 45px;
  text-align: center;
  color: #ffffff;
}

@media (max-width: 576px) {
  .testing-container-wrap {
    padding: 12px;
    border: none;
  }
}
